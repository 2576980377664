<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";


//import Chart from "@/components/widgets/chart";
import Stat from "@/components/widgets/widget-stat";
//import Transaction from "@/components/widgets/transaction";
//import Chat from "@/components/widgets/chat";
//import Activity from "@/components/widgets/activity";

import {  sparklineChartData, salesDonutChart, radialBarChart } from "/src/router/views/data.js";
import {   smilAnimationChart,simpleLineChart,donutAnimateChart,stackBarChart,simplePieChart,overlappingBarChart } from "/src/router/views/charts/data-chartist";
import  { lineChart } from "/src/router/views/charts/echart/data";
import LineChart from "/root/vue/Admin/src/router/views/charts/chartjs/line-chart.vue";
import  BarChart  from "/root/vue/Admin/src/router/views/charts/chartjs/bar-chart.vue"

import  stackedAreaChart from "/root/vue/Admin/src/router/views/charts/apex.vue";

export default {
  page: {
    title: "Dashboard1",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    Stat,
    LineChart,
    BarChart,


  },
  data() {
    //this.get_gross_sales();
    //alert("In UI "+tot);
    return {
      
     // linewithDataChart: linewithDataChart,
      stackedAreaChart: stackedAreaChart,

      lineChart: lineChart,
      overlappingBarChart: overlappingBarChart,
      stackBarChart: stackBarChart,
      donutAnimateChart: donutAnimateChart,
      smilAnimationChart: smilAnimationChart,
      sparklineChartData: sparklineChartData,
      salesDonutChart: salesDonutChart,
      radialBarChart: radialBarChart,
      simplePieChart: simplePieChart,
      simpleLineChart: simpleLineChart,
      
      statData: [
        {
          title: "Orders",
          image: require("@/assets/images/services-icon/01.png"),
          value: "0000",
          subText: "+ 12%",
          color: "success"
        },
        {
          title: "Revenue",
          image: require("@/assets/images/services-icon/02.png"),
          value: "52,368",
          subText: "- 28%",
          color: "danger"
        },
        {
          title: "Average Price",
          image: require("@/assets/images/services-icon/03.png"),
          value: "15.8",
          subText: "00%",
          color: "info"
        },
        {
          title: "Product Sold",
          image: require("@/assets/images/services-icon/04.png"),
          value: "2436",
          subText: "+ 84%",
          color: "warning"
        },

        {
          title: "Received",
          image: require("@/assets/images/services-icon/01.png"),
          value: "222",
          subText: "+ 12%",
          color: "success"
        }
    ],
  }; //return
    
  },
  methods: {
             async get_gross_sales(){
              //alert(this.$remoteUrl);
              //const wsurl=`${this.$remoteUrl}/api/method/frappe.auth.get_sales_grand_total`;
              //alert(wsurl);
              let res=await fetch(`${this.$remoteUrl}/api/method/frappeauth_app.authentication.get_company_sales_td`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization':'token 6e970039c52cbd4:f7b04199aade61e'
                    }
                });
                
                    //alert("RESP="+res.status);
                    let result =  await res.json();
              var sp=((result.data[0].MTD-result.data[0].LMTD)/result.data[0].MTD)*100;
              var status='';
              if(sp>0)
                status='success';
              else if(sp<0)
                status='danger';
              else
              status='info';
            //alert(result.data[0].MTD);
            if(res.status==200){
                    this.statData= [
        {
          title: "Orders",
          image: require("@/assets/images/services-icon/01.png"),
          value: result.data[0].MTD.toFixed(2).toString(),
          subText: sp.toFixed(2).toString()+"%",
          color: status
        },
        {
          title: "Revenue",
          image: require("@/assets/images/services-icon/02.png"),
          value: "52,36800",
          subText: "- 28%",
          color: "danger"
        },
        {
          title: "Average Price",
          image: require("@/assets/images/services-icon/03.png"),
          value: "15.800",
          subText: "00%",
          color: "info"
        },
        {
          title: "Product Sold",
          image: require("@/assets/images/services-icon/04.png"),
          value: "243600",
          subText: "+ 84%",
          color: "warning"
        },

        {
          title: "Received",
          image: require("@/assets/images/services-icon/01.png"),
          value: "22200",
          subText: "+ 12%",
          color: "success"
        }

    ]
  }
     return res.status;
  //return( this.$frappe.get_sales_grand_total());
  }
  },
   mounted () {
    this.get_gross_sales();
  }
};


</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Stacked Area1</h4>
           
            <apexchart
              
            ratio="ct-chart"
              class="apex-charts"
              dir="ltr"
              height="380"
              type="area"
              :series="stackedAreaChart.series"
              :options="stackedAreaChart.chartOptions"
            ></apexchart>
          
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>


    <div class="col-lg-10">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Bar Chart</h4>
            <div class="row justify-content-center">
              <div class="col-sm-4">
                <div class="text-center">
                  <h5 class="mb-0 font-size-20">2541</h5>
                  <p class="text-muted">Activated</p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="text-center">
                  <h5 class="mb-0 font-size-20">84845</h5>
                  <p class="text-muted">Pending</p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="text-center">
                  <h5 class="mb-0 font-size-20">12001</h5>
                  <p class="text-muted">Deactivated</p>
                </div>
              </div>
            </div>
            <!-- Bar Chart -->
            <BarChart :height="300" />
          </div>
        </div>
      </div>

    <!-- start page title -->
    <div class="row align-items-center">
      <div class="col-sm-8">
        <div class="page-title-box">
          <h4 class="font-size-18">Dashboard</h4>
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item active">Welcome to Printechs Dashboard</li>
          </ol>
        </div>
      </div>

      <div class="col-sm-6">
        <div class="float-end d-none d-md-block">
          <b-dropdown right variant="primary" menu-class="dropdown-menu-end">
            <template v-slot:button-content>
              <i class="mdi mdi-cog me-2"></i> Settings
            </template>
            <b-dropdown-item>Action</b-dropdown-item>
            <b-dropdown-item>Another action</b-dropdown-item>
            <b-dropdown-item>Something else here</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item>Separated link</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-xl-3 col-md-6" v-for="stat of statData" :key="stat.icon">
        <Stat
          :title="stat.title"
          :image="stat.image"
          :subText="stat.subText"
          :value="stat.value"
          :color="stat.color"
        />
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Monthly Earning</h4>         
          </div>
          <div>
              <chartist
              ratio="ct-chart"
      
              :data="overlappingBarChart.data"
              :options="overlappingBarChart.options"
              type="Bar"
            ></chartist>
            </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Monthly Earning</h4>         
          </div>
          <div>
              <chartist
              ratio="ct-chart"
              :data="simplePieChart.data"
              :options="simplePieChart.options"
              type="Pie"
            ></chartist>
            </div>
        </div>
      </div>
    </div>
    <!-- end row -->



    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Monthly Earning</h4>         
          </div>
          <div>
              <chartist
              ratio="ct-chart"
              :data="stackBarChart.data"
              :options="stackBarChart.options"
              type="Bar"
            ></chartist>
            </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Monthly Earning</h4>         
          </div>
          <div>
              <chartist
              ratio="ct-chart"
              :data="donutAnimateChart.data"
              :options="donutAnimateChart.options"
              type="Pie"
            ></chartist>
            </div>
        </div>
      </div>
    </div>
    <!-- end row -->


    
    <div class="row">
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Monthly Earning</h4>         
          </div>
          <div>
              <chartist
              ratio="ct-chart"
              :data="smilAnimationChart.data"
              :options="smilAnimationChart.options"
              type="Line"
            ></chartist>
            </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Monthly Earning</h4>         
          </div>
          <div>
              <chartist
              ratio="ct-chart"
              :data="simpleLineChart.data"
              :options="simpleLineChart.options"
              type="Line"
            ></chartist>
            </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Line Chart</h4>
            <div class="row justify-content-center">
              <div class="col-sm-4">
                <div class="text-center">
                  <h5 class="mb-0 font-size-20">86541</h5>
                  <p class="text-muted">Activated</p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="text-center">
                  <h5 class="mb-0 font-size-20">2541</h5>
                  <p class="text-muted">Pending</p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="text-center">
                  <h5 class="mb-0 font-size-20">102030</h5>
                  <p class="text-muted">Deactivated</p>
                </div>
              </div>
            </div>
            <!-- Line Chart -->  
            <chartist
              ratio="ct-chart"
              :data="overlappingBarChart.data"
              :options="overlappingBarChart.options"
              type="Bar"
            ></chartist>
           
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Line Chart</h4>
            <div class="row justify-content-center">
              <div class="col-sm-4">
                <div class="text-center">
                  <h5 class="mb-0 font-size-20">86541</h5>
                  <p class="text-muted">Activated</p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="text-center">
                  <h5 class="mb-0 font-size-20">2541</h5>
                  <p class="text-muted">Pending</p>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="text-center">
                  <h5 class="mb-0 font-size-20">102030</h5>
                  <p class="text-muted">Deactivated</p>
                </div>
              </div>
            </div>
            <!-- Line Chart -->
            <LineChart :height="300" />
          </div>
        </div>
      </div>

     
  </Layout>
</template>